<template>
  <div class="container">
    <div class="top">
      <div class="top-danwei">单位：万元</div>
      <!-- <img class="top-banner" src="../src/assets/bg-header@3x.png" alt="" /> -->
      <img class="top-title" src="../src/assets/title@3x.png" alt="" />
      <div class="clock">
        <p>{{ weekDay }}</p>
        <p>
          <span class="date">{{ date }}</span>
          <span class="time">{{ time }}</span>
        </p>
      </div>
    </div>
    <div class="main">
      <index></index>
    </div>
    <img class="bg-bt" src="../src/assets/bg-bottom.png" alt="" />
  </div>
</template>
<script>
import index from "./components/index.vue";
export default {
  name: "Home",
  data() {
    return {
      weekDay: "",
      date: "",
      time: "",
    };
  },
  components: {
    index,
  },
  beforeMount() {
    this.getTime();
    setInterval(this.getTime, 1000);
  },
  methods: {
    getTime() {
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      this.weekDay = weeks[day];
      let date =
        now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
      this.date = date;
      let h = now.getHours();
      let m = now.getMinutes();
      let s = now.getSeconds();
      if (h < 10) h = "0" + h.toString();
      if (m < 10) m = "0" + m.toString();
      if (s < 10) s = "0" + s.toString();
      this.time = h + ":" + m + ":" + s;
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* min-height: 1080px; */
  background: #213b50;
}
.container {
  background-image: url("../src/assets/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
}
.bg-bt {
  position: fixed;
  bottom: -40%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.4;
  height: 80%;
}
.top {
  width: 100%;
  height: 11%;
  position: relative;
  background-image: url("../src/assets/bg-header@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-danwei{
  position: absolute;
  left: 2%;
  color: #eeeeee;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  z-index: 9;
}
/* .top-banner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
.top-title {
  margin-top: -10px;
  width: 20%;
  z-index: 99;
}
.clock {
  position: absolute;
  right: 2%;
  color: #eeeeee;
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 1px;
  z-index: 9;
}
.time {
  margin-left: 20px;
}
.main {
  box-sizing: border-box;
  padding: 30px 20px;
  height: 89%;
}
</style>
