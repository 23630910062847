<!-- shops -->
<template>
  <div class="container">
    <div class="top">
      <!-- <img class="top-banner" src="../src/assets/bg-header@3x.png" alt="" /> -->
      <img class="top-label" src="../src/assets/title@3x.png" alt="" />
      <div class="clock">
        <p>{{ weekDay }}</p>
        <p>
          <span class="date">{{ date }}</span>
          <span class="time">{{ time }}</span>
        </p>
      </div>
    </div>
    <div class="main">
      <div class="table-box">
        <div class="flex-between">
          <div class="back" @click="backHome">
            <span
              ><img class="back-icon" src="./assets/home.png" alt=""
            /></span>
            <span class="back-text">返回首页</span>
          </div>
          <div class="switch-btns flex-center">
            <div
              class="switch-btn"
              :class="activeBtn == 0 ? 'switch-btn-active' : ''"
              @click="activeBtn = 0"
            >
              流水实况
            </div>
            <div
              class="switch-btn"
              :class="activeBtn == 1 ? 'switch-btn-active' : ''"
              @click="activeBtn = 1"
            >
              明细表格
            </div>
          </div>
        </div>
        <div class="pro-title">{{ project }}</div>
        <el-carousel
          ref="swiper"
          height="600px"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item name="tableA">
            <el-table
              class="table customer-no-border-table"
              height="600"
              :data="turnover"
              row-class-name="table-row"
              header-cell-class-name="table-header"
              :header-cell-style="headerStyle"
              :cell-style="cellStyle"
            >
              <el-table-column prop="time" label="创建时间"></el-table-column>
              <el-table-column prop="title" label="名称"></el-table-column>
              <el-table-column prop="amount" label="营业额"></el-table-column>
              <el-table-column
                prop="point_pay"
                label="消费礼、积分抵扣"
              ></el-table-column>
              <el-table-column
                prop="wallet_pay"
                label="钱包支付"
              ></el-table-column>
              <el-table-column prop="quan_pay" label="券抵扣"></el-table-column>
              <el-table-column
                prop="income"
                label="实际营业额"
              ></el-table-column>
              <el-table-column prop="user" label="付款人">
                <template slot-scope="scope">
                  <div class="flex-center">
                    <img
                      class="table-img"
                      :src="scope.row.user.avatar"
                      min-width="40"
                      height="40"
                    />
                    <span class="username" style="margin-left: 12px">{{
                      scope.row.user.nickname
                    }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="turnover-list">
              <div class="turnover-head">流水表头</div>
              <div class="turnover" v-for="(item, index) in 10" :key="index">
                流水
              </div>
            </div> -->
          </el-carousel-item>
          <el-carousel-item name="tableB">
            <el-table
              class="table customer-no-border-table"
              height="600"
              :data="shops"
              row-class-name="table-row"
              header-cell-class-name="table-header"
              :header-cell-style="headerStyle"
              :cell-style="cellStyle"
            >
              <el-table-column
                type="index"
                width="80"
                label="序号"
              ></el-table-column>
              <el-table-column label="商户">
                <template slot-scope="scope">
                  <div class="flex-left" style="margin-left: 10%">
                    <img
                      class="table-img"
                      :src="scope.row.logo"
                      min-width="40"
                      height="40"
                    />
                    <span class="username" style="margin-left: 12px">{{
                      scope.row.name
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="vip"
                label="会员卡办理数量"
              ></el-table-column>
              <el-table-column label="当日营业情况">
                <el-table-column prop="amount" label="营业额"></el-table-column>
                <el-table-column
                  prop="discount"
                  label="消费礼、积分抵扣"
                ></el-table-column>
                <el-table-column
                  prop="online"
                  label="网络销售额（美团、大众点评）"
                ></el-table-column>
                <el-table-column
                  prop="income"
                  label="实际营业额"
                ></el-table-column>
              </el-table-column>
              <el-table-column
                prop="income_m"
                label="当月累计营业额"
              ></el-table-column>
            </el-table>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <img class="bg-bt" src="../src/assets/bg-bottom.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Shop",
  data() {
    return {
      activeBtn: 0,
      weekDay: "",
      date: "",
      time: "",
      project: "",
      id: "",
      allAlign: "center",
      shops: [],
      turnover: [], //流水
      Interval1:'', //所有定时器
      Interval2:''
    };
  },
  watch: {
    activeBtn(newVal) {
      let swiper = this.$refs.swiper;
      if (newVal == 1) {
        swiper.setActiveItem("tableB");
      } else if (newVal == 0) {
        swiper.setActiveItem("tableA");
      }
    },
  },
  beforeMount() {
    this.getTime();
    this.Interval1 = setInterval(this.getTime, 1000);
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.project = this.$route.query.project;
      console.log(this.$route.query.id);
    }
    this.getShops();
    this.getTurnover();
    this.Interval2 = setInterval(this.getTurnover, 5000);
  },
  methods: {
    getShops() {
      let ts = Math.round(new Date().getTime() / 1000).toString();
      let url = "screen/api/project";
      let params = {
        id: this.id,
        name: this.project,
        timestamp: ts,
      };
      this.$axios.post(url, params).then((res) => {
        console.log(res);
        let arr = [].concat(res.data.data);
        for (let item of arr) {
          item.income_m = parseFloat(Number(item.income_m).toFixed(2));
          item.amount = parseFloat(Number(item.amount).toFixed(2));
          item.income = parseFloat(Number(item.income).toFixed(2));
          item.online = parseFloat(Number(item.online).toFixed(2));
          item.vip = Number(item.vip);
        }
        this.shops = arr;
      });
    },
    getTurnover() {
      let now = new Date();
      let m =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      let d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      let date = now.getFullYear() + "-" + m + "-" + d;
      let url = "screen/api/areaflow";
      let params = {
        aid: this.id,
        date: date,
      };
      this.$axios.post(url, params).then((res) => {
        let data = res.data.data;
        if (res.data.state == 1) {
          let arr = [].concat(data);
          this.turnover = arr;
        }
        console.log(res, this.turnover, "商圈流水");
      });
    },
    textToNum(row, column) {
      // return parseFloat(row.income_m.toFixed(2)).toLocaleString()
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return "background-color:transparent;text-align: center;font-size:16px;font-weight:500;";
    },
    cellStyle({ row, rowIndex }) {
      return "text-align:center;font-size:16px;font-weight:500;";
    },
    backHome() {
      this.$router.push("/home");
    },
    getTime() {
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      this.weekDay = weeks[day];
      let date =
        now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
      this.date = date;
      let h = now.getHours();
      let m = now.getMinutes();
      let s = now.getSeconds();
      if (h < 10) h = "0" + h.toString();
      if (m < 10) m = "0" + m.toString();
      if (s < 10) s = "0" + s.toString();
      this.time = h + ":" + m + ":" + s;
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval1)
    clearInterval(this.Interval2)
  } //清除定时器
};
</script>

<style>
.vxe-body--column {
  background-color: aquamarine;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* min-height: 1080px; */
  background: #213b50;
}
.container {
  background-image: url("../src/assets/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
}
.bg-bt {
  position: fixed;
  bottom: -40%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.4;
  height: 80%;
}
.top {
  width: 100%;
  height: 11%;
  position: relative;
  background-image: url("../src/assets/bg-header@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .top-banner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
.top-label {
  margin-top: -10px;
  width: 20%;
  z-index: 99;
}
.clock {
  position: absolute;
  right: 2%;
  color: #eeeeee;
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 1px;
  z-index: 9;
}
.time {
  margin-left: 20px;
}
.main {
  box-sizing: border-box;
  padding: 30px 40px;
}

.table-box {
  width: 90%;
  margin: 0 auto;
}
.table-img {
  border-radius: 100px;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.back {
  width: 180px;
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.back-icon {
  width: 30px;
  height: 30px;
}
.back-text {
  margin: 5px;
}

.switch-btns {
  border: 1px solid rgb(79, 150, 251);
  border-radius: 100px;
  overflow: hidden;
}
.switch-btn:first-child {
  border-right: 1px solid rgb(79, 150, 251);
}
.switch-btn {
  color: rgb(79, 150, 251);
  font-weight: bold;
  min-width: 100px;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.switch-btn-active {
  color: #fff;
  background: rgba(79, 150, 251, 0.6);
}

.turnover-list {
  height: 100%;
}
.turnover-head,
.turnover {
  height: calc(100% / 10);
}

/* 隐藏轮播图指示器 */
.el-carousel__indicators,
.el-carousel__indicators--horizontal {
  display: none;
}

/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  border: 0;
  background-color: transparent;
}

/* 使表格背景透明 */
.el-table th,
.el-table tr {
  background-color: transparent;
}
/* 去除右边框 */
.el-table::after {
  width: 0;
}
/* 去除表头底部边框 */
.customer-no-border-table td,
.customer-no-border-table th.is-leaf {
  border: none;
}
/*表格左，上边框*/
.customer-no-border-table .el-table--border,
.el-table--group {
  border: none;
}
/* 表格底部边框 */
.customer-no-border-table::before {
  width: 0;
}
/* 去除hover效果 */
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 0, 0, 0) !important;
}
.table {
  width: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}
.table-header {
  color: #ffffff;
}
.table-row {
  text-align: center;
  color: #ffffff;
}
.pro-title {
  width: 100%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
</style>
