import Vue from 'vue'
import {
  Table,
  TableColumn,
  Carousel,
  CarouselItem, Collapse, CollapseItem, Dropdown, DropdownItem, DropdownMenu
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import './axios'

import router from './router'

// axios.defaults.baseURL = 'http://jiujie.91ziyan.com/screen/api/base';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.config.productionTip = false
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
