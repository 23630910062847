import Vue from "vue";
import axios from "axios";

// 将请求格式改为formdata
axios.defaults.transformRequest = function(data) {
  let ret = "";
  for (let it in data) {
    ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
  }
  return ret;
};

let config = {
  baseURL: "https://jiujie.91ziyan.com/",
  //生产环境 https://jiujie.91ziyan.com/
  //测试环境 http://test.jiujie.91ziyan.com/
};

const _axios = axios.create(config);

// _axios.interceptors.request.use(
//   function(config) {
//     // Do something before request is sent
//     // 添加10位unix时间戳
//     let timestamp = Math.round(new Date().getTime() / 1000).toString();
//     config.data.timestamp = timestamp;
//     return config;
//   },
//   function(error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
