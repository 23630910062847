import Vue from 'vue'
import Router from 'vue-router'
import home from '../home.vue'
import shops from '../shops.vue'
import store from '../store.vue'
Vue.use(Router)

const routes = [{
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "home",
    component: home
  },
  {
    path: "/shops", //点击商圈名的详情页
    name: "shops",
    component: shops
  },
  {
    path: "/store", //单个店铺的30日排行榜
    name: "store",
    component: store
  },
  {
    path: "/shop",
    component:()=>import('../view/shop'),
    children:[
      {path:'',component:()=>import('../view/table')},
      {path:'table',component:()=>import('../view/table')}, //底部有赞营业额，右上今日订单数
      {path: 'project',component:()=>import('../view/project')}, //商圈里的营业额详情，
      {path: 'cm',component:()=>import('../view/customer-merchant')} //合作商户，客户数详情
    ]
  },
  //优惠券模块
  {
    path: "/coupon",
    name: "coupon",
    component: ()=>import('../view/coupon')
  },
  //中间区域的今日、昨日营业额
  {
    path: '/turnover',
    name: 'turnover',
    component: ()=>import('../view/money_today_yestoday')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
