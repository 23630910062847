<!-- shops -->
<template>
  <div class="container">
    <div class="top">
      <!-- <img class="top-banner" src="../src/assets/bg-header@3x.png" alt="" /> -->
      <img class="top-label" src="../src/assets/title@3x.png" alt="" />
      <div class="clock">
        <p>{{ weekDay }}</p>
        <p>
          <span class="date">{{ date }}</span>
          <span class="time">{{ time }}</span>
        </p>
      </div>
    </div>
    <div class="main">
      <div class="table-box">
        <div class="table-top">
          <div class="back" @click="backHome">
            <span
              ><img class="back-icon" src="./assets/home.png" alt=""
            /></span>
            <span class="back-text">返回首页</span>
          </div>
          <div class="store-title">
            <img class="logo" :src="store.logo" alt="" />
            <span class="title">{{ store.name }}</span>
          </div>
        </div>
        <div id="Store"></div>
      </div>
    </div>
    <img class="bg-bt" src="../src/assets/bg-bottom.png" alt="" />
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Shop",
  data() {
    return {
      project: "",
      id: '',
      days: "30",
      weekDay: "",
      date: "",
      time: "",
      allAlign: "center",
      store: {},
      dataX: [],
      dayaY: [],
      total: [],
      quan: [],
      point: [],
      income: [],
    };
  },

  beforeMount() {
    this.getTime();
    setInterval(this.getTime, 1000);
  },
  mounted() {
    // if (this.$route.query.project) {
    //   this.project = this.$route.query.project;
    // }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getStore();
  },
  methods: {
    getStore() {
      let ts = Math.round(new Date().getTime() / 1000).toString();
      let url = "screen/api/shop";
      let params = {
        id: this.id,
        // project: this.project,
        timestamp: ts,
        days: this.days,
      };
      this.$axios.post(url, params).then((res) => {
        console.log(res);
        this.store = res.data.data.merchant;
        this.dataX = res.data.data.x;
        this.dataY = res.data.data.y;

        setTimeout(() => {
          this.drawStore();
        }, 500);
      });
    },
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return "background-color:transparent;text-align: center;font-size:16px;font-weight:500;";
    },
    cellStyle({ row, rowIndex }) {
      return "text-align:center;font-size:16px;font-weight:500;";
    },
    backHome() {
      this.$router.push("/home");
    },
    getTime() {
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      this.weekDay = weeks[day];
      let date =
        now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
      this.date = date;
      let h = now.getHours();
      let m = now.getMinutes();
      let s = now.getSeconds();
      if (h < 10) h = "0" + h.toString();
      if (m < 10) m = "0" + m.toString();
      if (s < 10) s = "0" + s.toString();
      this.time = h + ":" + m + ":" + s;
    },
    drawStore() {
      let y = this.dataY;
      for (let item in y) {
        this.income.push(y[item].income);
        this.point.push(y[item].point);
        this.quan.push(y[item].quan);
        this.total.push(y[item].total);
      }
      console.log("data", this.income, this.point, this.quan);
      let center = echarts.init(document.getElementById("Store"));
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: function (p) {
            return (
              p[0].name +
              "<br />" +
              p[0].marker +
              p[0].seriesName +
              ": " +
              p[0].value +
              "元" +
              "<br />" +
              p[1].marker +
              p[1].seriesName +
              ": " +
              p[1].value +
              "元" +
              "<br />" +
              p[2].marker +
              p[2].seriesName +
              ": " +
              p[2].value +
              "元"
            );
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["营业额", "积分抵扣", "消费券"],
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.dataX,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cccccc",
            },
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cccccc",
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
            showDetail: true,
            textStyle: {
              color: "#fff",
            },
            start: 0,
            end: 100,
          },
          {
            type: "inside",
            xAxisIndex: [0],
            filterMode: "filter",
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: "营业额",
            type: "bar",
            stack: "total",
            color: "#6D86CE",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: this.income,
          },
          {
            name: "积分抵扣",
            type: "bar",
            stack: "total",
            color: "#F2CC76",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: this.point,
          },
          {
            name: "消费券",
            type: "bar",
            stack: "total",
            color: "#E87E82",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: this.quan,
          },
        ],
      };
      center.setOption(option);
    },
  },
};
</script>

<style>
.vxe-body--column {
  background-color: aquamarine;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* min-height: 1080px; */
  background: #213b50;
}
.container {
  background-image: url("../src/assets/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
}
.bg-bt {
  position: fixed;
  bottom: -40%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.4;
  height: 80%;
}
.top {
  width: 100%;
  height: 11%;
  position: relative;
  background-image: url("../src/assets/bg-header@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .top-banner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
.top-label {
  margin-top: -10px;
  width: 20%;
  z-index: 99;
}
.clock {
  position: absolute;
  right: 2%;
  color: #eeeeee;
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 1px;
  z-index: 9;
}
.time {
  margin-left: 20px;
}
.main {
  box-sizing: border-box;
  padding: 30px 40px;
}

.table-box {
  width: 90%;
  margin: 0 auto;
}
.table-top {
  width: 92%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.back-icon {
  width: 30px;
  height: 30px;
  display: block;
}
.back-text {
  margin: 5px;
}
.store-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.title {
  font-size: 20px;
  color: #fff;
}
#Store {
  height: 500px;
  z-index: 999;
  margin-top: 100px;
}

/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  border: 0;
  background-color: transparent;
}

/* 使表格背景透明 */
.el-table th,
.el-table tr {
  background-color: transparent;
}
/* 去除右边框 */
.el-table::after {
  width: 0;
}
/* 去除表头底部边框 */
.customer-no-border-table td,
.customer-no-border-table th.is-leaf {
  border: none;
}
/*表格左，上边框*/
.customer-no-border-table .el-table--border,
.el-table--group {
  border: none;
}
/* 表格底部边框 */
.customer-no-border-table::before {
  width: 0;
}
/* 去除hover效果 */
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(0, 0, 0, 0) !important;
}
.table {
  width: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}
.table-header {
  color: #ffffff;
}
.table-row {
  text-align: center;
  color: #ffffff;
}
</style>
