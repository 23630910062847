<!-- index -->
<template>
  <div class="index">
    <div class="left">
      <div class="left-topimg">
        <img class="left-img" src="../assets/left-top.png" alt="" />
      </div>
      <div class="project_main">
        <div class="project" v-for="item in project" :key="item.id">
          <div class="project-top">
            <img class="project-img" src="../assets/layer@3x.png" alt="" />
            <div class="project-title">
              <!-- <div class="type" v-if="index == 0">项目名</div> -->
              <!-- <router-link to="/shops"> -->
              <div class="project-name cursor_href" @click="toshop(item.id,item.name)">
                {{ item.name }}
              </div>
              <!-- </router-link> -->
            </div>
          </div>
          <div class="project-msg">
            <div class="msg cursor_href" @click="toTable(item.id,item.name)">
              <span class="msg-title">营业额</span>
              <span class="msg-num"> {{ item.offline }} </span>
            </div>
            <div class="msg">
              <span class="msg-title">金豆消耗</span>
              <span class="msg-num"> {{ item.point_cost }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <img
        class="c-img1"
        :class="count > 2 ? 'show' : 'hidden'"
        src="../assets/yuan1@2x.png"
        alt=""
      />
      <img
        class="c-img2"
        :class="count > 1 ? 'show' : 'hidden'"
        src="../assets/yuan2@2x.png"
        alt=""
      />
      <img
        class="c-img3"
        :class="count > 0 ? 'show' : 'hidden'"
        src="../assets/yuan3@2x.png"
        alt=""
      />
      <img class="c-img4" src="../assets/yuan4@2x.png" alt="" />
      <img class="rotation" src="../assets/xiaotiao@2x.png" alt="" />
      <div id="centerC" class="center-C"></div>
      <img class="c-img5" src="../assets/yuan5@2x.png" alt="" />
      <div class="c-box cursor_href" @click="$router.push({path:'/turnover',query:{time:'today'}})">
        <div class="c-money">
          {{ money.today }}
        </div>
        <img class="c-img6" src="../assets/img-bling@3x.png" alt="" />
        <span class="c-text">今日营业额</span>
      </div>
    </div>
    <div class="center_b">
      <div class="center_b_l">
        <div class="center_b_l_t">
          <div class="center_b_l_t_l cursor_href" @click="$router.push({path:'/shop/table',query:{name:'youzan'}})">
            <div><span>{{ money.online }}</span></div>
            <div>有赞营业额</div>
          </div>
          <div class="center_b_l_t_r">
            <div><span>{{ point.online }}</span></div>
            <div>有赞消耗金豆</div>
          </div>
        </div>
      </div>

      <div class="center_b_r">
        <div class="center_b_r_item cursor_href" @click="toCoupon('0')">
          <div>{{ msg.get_num }}</div>
          <div>今日领取</div>
        </div>
        <div class="center_b_r_item cursor_href" @click="toCoupon('1')">
          <div>{{ msg.use_num }}</div>
          <div>今日使用</div>
        </div>
        <div class="center_b_r_item">
          <div>{{ msg.all_num }}</div>
          <div>累计使用</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="total">
        <div class="total-title">
          <div class="iconbox">
            <img class="icon" src="../assets/icon.png" alt="" />
          </div>
          <span class="title-1">会员存量</span>
        </div>
        <div class="vip_main">
          <div class="cursor_href" @click="$router.push({path:'/shop/cm',query:{name:'customerall'}})"><span>累计客户数</span><span>{{ msg.vip }}</span></div>
          <div class="cursor_href" @click="$router.push({path:'/shop/cm',query:{name:'customertoday'}})"><span>今日新增客户</span><span>{{ msg.today_member }}</span></div>
          <div class="cursor_href" @click="$router.push({path:'/shop/table',query:{name:'todayorders'}})"><span>今日订单数</span><span>{{ msg.today_all_order }}</span></div>
          <div><span>今日客单价</span><span>{{ msg.percent }}</span></div>
        </div>
      </div>
      <div class="details">
        <div class="total-title">
          <div class="iconbox">
            <img class="icon" src="../assets/icon.png" alt="" />
          </div>
          <span class="title-1">金豆详情</span>
        </div>
        <div class="bean">
          <div class="bean-l">
            <img class="bean-img" src="../assets/shengchan@3x.png" alt="" />
            <div class="bean-msg">
              <div class="bean-count">{{ point.produce_today }}</div>
              <div class="bean-text">今日生产金豆</div>
            </div>
          </div>
          <div class="bean-r">
            <img class="bean-img" src="../assets/xiaohao@3x.png" alt="" />
            <div class="bean-msg">
              <div class="bean-count">{{ point.cost_today }}</div>
              <div class="bean-text">今日消耗金豆</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ranking">
        <div class="total-title">
          <div class="total-title-l">
            <div class="iconbox">
              <img class="icon" src="../assets/icon.png" alt="" />
            </div>
            <span class="title-1">当日营业额排名</span>
          </div>
          <div class="total-title-r">
            <el-dropdown @command="dropdownChange">
              <span class="el-dropdown-link">
                {{ change_ranking }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="index_dropdown_menu">
                <el-dropdown-item v-for="v in project" :command="v">{{ v.name }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="ranking_main">
          <div class="cursor_href" v-for="v in merchant" :key="v.id" @click="goStore(v.id)">
            <span>{{ v.name }}</span>
            <span>{{ v.amount }}￥</span>
          </div>
        </div>
      </div>
    </div>
    <div class="two">
      <img class="min-img3" src="../assets/yuan3@2x.png" alt="" />
      <img class="min-img4" src="../assets/yuan4@2x.png" alt="" />
      <img class="rotation-min" src="../assets/xiaotiao@2x.png" alt="" />
      <div id="centerL" class="center-min"></div>
      <img class="min-img5" src="../assets/yuan5@2x.png" alt="" />
      <div class="min-box cursor_href" @click="$router.push({path:'/turnover',query:{time:'yestoday'}})">
        <div class="min-money">
          {{ money.yestoday }}
        </div>
        <img class="min-img6" src="../assets/img-bling@3x.png" alt="" />
        <span class="min-text">昨日营业额</span>
      </div>
    </div>
    <div class="two2">
      <img class="min-img3" src="../assets/yuan3@2x.png" alt="" />
      <img class="min-img4" src="../assets/yuan4@2x.png" alt="" />
      <img class="rotation-min" src="../assets/xiaotiao@2x.png" alt="" />
      <div id="centerR" class="center-min"></div>
      <img class="min-img5" src="../assets/yuan5@2x.png" alt="" />
      <div class="min-box">
        <div class="min-money">
          {{ point.cost_yestoday }}
        </div>
        <img class="min-img6" src="../assets/img-bling@3x.png" alt="" />
        <span class="min-text">昨日金豆消耗</span>
      </div>
    </div>
    <div class="merchant_number cursor_href" @click="$router.push({path:'/shop/cm',query:{name:'merchant'}})">
      <img src="../assets/merchant-management.png">
      <div class="name">合作商户</div>
      <div class="number">{{ msg.comch_num }}</div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import "echarts-liquidfill";
export default {
  name: "",
  data() {
    return {
      msg: {},
      project: [],
      merchant: [],
      count: 0,
      money:{},
      point:{},
      change_ranking:''//当前排名商圈
    };
  },
  mounted() {
    setInterval(this.getData, 1800000);
    setInterval(this.show, 600);
    this.getData();
  },
  methods: {
    toshop(id,name) {
      this.$router.push({ path: "/shops", query: { id: id,project:name } });
    },
    toTable(id,name){
      this.$router.push({path:'/shop/project',query:{id,name}})
    },
    toCoupon(module){
      console.log('toCoupon')
      this.$router.push({path:'/coupon',query:{module}})
    },
    dropdownChange(v){
      console.log(v)
      this.change_ranking = v.name
      this.getRanking(v.id)
    },
    getRanking(linkageid){ //获得商圈排名
      this.$axios.post('screen/api/get_rank',{linkageid}).then(res=>{
        this.merchant = res.data.data.slice(0,5)
      })
    },
    show() {
      if (this.count < 3) {
        this.count = this.count + 1;
      } else {
        this.count = 0;
      }
    },
    getData() {
      let url = "screen/api/base";
      this.$axios.post(url).then((res) => {
        this.getRanking(res.data.data.project[0].id)
        this.msg = res.data.data;
        this.project = res.data.data.project;
        this.change_ranking = res.data.data.project[0].name
        this.money = res.data.data.money;
        this.point = res.data.data.point
        setTimeout(() => {
          this.drawCenterC();
          this.drawCenterL();
          this.drawCenterR();
          /*this.drawYear();
          this.drawSeason();
          this.drawMonth();
          this.drawRanking();*/
        }, 200);
      });
    },
    // rank商家排序
    /*compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },*/
    // 前三名颜色
    /*topThree(o, index, id, project) {
      let obj = {};
      obj.value = o;
      obj.id = id;
      obj.project = project;
      if (index < 3) {
        obj.itemStyle = {
          color: "#E87E82",
        };
      }
      return obj;
    },*/
    drawCenterL() {
      var getmax = 100;
      let center = echarts.init(document.getElementById("centerL"));
      let option = {
        angleAxis: {
          show: false,
          max: (getmax * 360) / 270, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 8, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: "120%",
        },
        series: [
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  // color: "#1598FF",
                  // opacity: 0.8,
                  // borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#28B0FE",
                      },
                      {
                        offset: 1,
                        color: "#8670F7",
                      },
                    ]),
                  },
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
        ],
      };
      center.setOption(option);
    },
    drawCenterR() {
      var getmax = 100;
      let center = echarts.init(document.getElementById("centerR"));
      let option = {
        angleAxis: {
          show: false,
          max: (getmax * 360) / 270, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 8, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: "120%",
        },
        series: [
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  // color: "#1598FF",
                  // opacity: 0.8,
                  // borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#28B0FE",
                      },
                      {
                        offset: 1,
                        color: "#8670F7",
                      },
                    ]),
                  },
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
        ],
      };
      center.setOption(option);
    },
    drawCenterC() {
      var getmax = 100;
      let center = echarts.init(document.getElementById("centerC"));
      let option = {
        angleAxis: {
          show: false,
          max: (getmax * 360) / 270, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 15, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: "120%",
        },
        series: [
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  // color: "#1598FF",
                  // opacity: 0.8,
                  // borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#28B0FE",
                      },
                      {
                        offset: 1,
                        color: "#8670F7",
                      },
                    ]),
                  },
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
        ],
      };
      center.setOption(option);
    },
    goStore(id){
      this.$router.push({
        path: "/store",
        query: {
          id: id,
          // project: param.data.project,
        },
      });
    }
    /*drawYear() {
      var value = this.msg.year;
      var year = echarts.init(document.getElementById("year"));
      var option = {
        title: [
          {
            text: value,
            left: "43%",
            top: "35%",
            textAlign: "center",
            textStyle: {
              fontSize: "30",
              fontWeight: "400",
              color: "#fff",
              textAlign: "center",
              textBorderColor: "rgba(0, 0, 0, 0)",
              textShadowColor: "#000",
              textShadowBlur: "0",
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        polar: {
          radius: ["90%", "100%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: 100,
          clockwise: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            amplitude: 4.3,
            data: [
              {
                value: "0.3",
                direction: "left",
                itemStyle: {
                  normal: {
                    color: "#23405F",
                  },
                },
              },
            ],
            backgroundStyle: {
              borderWidth: 1,
              color: "transparent",
            },
            label: {
              normal: {
                formatter: "",
              },
            },
            outline: {
              show: false,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: "",
            type: "bar",
            roundCap: true,
            z: 2,
            showBackground: true,
            backgroundStyle: {
              color: "#15181e",
            },
            data: [75],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0.5, 1, [
                  {
                    offset: 0.3,
                    color: "#9DC3FF",
                  },
                  {
                    offset: 1,
                    color: "#6F82FF",
                  },
                ]),
              },
            },
          },
        ],
      };
      year.setOption(option);
    },*/
   /* drawSeason() {
      var value = this.msg.season;
      var season = echarts.init(document.getElementById("season"));
      var option = {
        title: [
          {
            text: value,
            left: "43%",
            top: "35%",
            textAlign: "center",
            textStyle: {
              fontSize: "30",
              fontWeight: "400",
              color: "#fff",
              textAlign: "center",
              textBorderColor: "rgba(0, 0, 0, 0)",
              textShadowColor: "#000",
              textShadowBlur: "0",
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        polar: {
          radius: ["90%", "100%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: 100,
          clockwise: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            amplitude: 4.3,
            data: [
              {
                value: "0.3",
                direction: "left",
                itemStyle: {
                  normal: {
                    color: "#23405F",
                  },
                },
              },
            ],
            backgroundStyle: {
              borderWidth: 1,
              color: "transparent",
            },
            label: {
              normal: {
                formatter: "",
              },
            },
            outline: {
              show: false,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: "",
            type: "bar",
            roundCap: true,
            z: 2,
            showBackground: true,
            backgroundStyle: {
              color: "#15181e",
            },
            data: [75],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0.5, 1, [
                  {
                    offset: 0.3,
                    color: "#E647A4",
                  },
                  {
                    offset: 1,
                    color: "#B052D6",
                  },
                ]),
              },
            },
          },
        ],
      };
      season.setOption(option);
    },*/
    /*drawMonth() {
      var value = this.msg.month;
      var month = echarts.init(document.getElementById("month"));
      var option = {
        title: [
          {
            text: value,
            left: "43%",
            top: "35%",
            textAlign: "center",
            textStyle: {
              fontSize: "30",
              fontWeight: "400",
              color: "#fff",
              textAlign: "center",
              textBorderColor: "rgba(0, 0, 0, 0)",
              textShadowColor: "#000",
              textShadowBlur: "0",
              textShadowOffsetX: 0,
              textShadowOffsetY: 1,
            },
          },
        ],
        polar: {
          radius: ["90%", "100%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: 100,
          clockwise: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            amplitude: 4.3,
            data: [
              {
                value: "0.3",
                direction: "left",
                itemStyle: {
                  normal: {
                    color: "#23405F",
                  },
                },
              },
            ],
            backgroundStyle: {
              borderWidth: 1,
              color: "transparent",
            },
            label: {
              normal: {
                formatter: "",
              },
            },
            outline: {
              show: false,
              itemStyle: {
                borderWidth: 0,
              },
              borderDistance: 0,
            },
          },
          {
            name: "",
            type: "bar",
            roundCap: true,
            z: 2,
            showBackground: true,
            backgroundStyle: {
              color: "#15181e",
            },
            data: [75],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0.5, 1, [
                  {
                    offset: 0.3,
                    color: "#DBDC6F",
                  },
                  {
                    offset: 1,
                    // color: "#DCAA5D",
                    color: "#DE9054",
                  },
                ]),
              },
            },
          },
        ],
      };
      month.setOption(option);
    },*/
    /*drawRanking() {
      let x = [];
      let num = [];
      let arr = this.merchant.sort(this.compare("income"));
      for (let item in arr) {
        x.push(arr[item].name);
        num.push(
          this.topThree(arr[item].income, item, arr[item].id, arr[item].project)
        );
      }
      num.reverse();
      x.reverse();
      console.log(num, x, "123");
      var rank = echarts.init(document.getElementById("rank"));
      var option = {
        tooltip: {
          trigger: "axis",
          formatter: function (p) {
            return (
              p[0].name +
              "<br />" +
              p[0].marker +
              p[0].seriesName +
              ":" +
              p[0].value +
              "元"
            );
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "10%",
          left: "0",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cccccc",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: "#fff",
                opacity: 0.2,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            data: x,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cccccc",
              },
            },
          },
        ],
        series: [
          {
            name: "营业额",
            type: "bar",
            barWidth: "20%",
            color: "#00FFF7", //柱状图颜色
            data: num,
          },
        ],
      };
      rank.setOption(option);
      rank.on("click", (param) => {
        // 可以使用下面的方式进行路由的切换
        // console.log(param)
        this.$router.push({
          path: "/store",
          query: {
            id: param.data.id,
            // project: param.data.project,
          },
        });
      });
    },*/
  },
};
</script>

<style scoped>
/*记得将隐藏的div类删除哦*/
.index {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.two {
  position: absolute;
  left: 23%;
  top: 30%;
}
.two2 {
  position: absolute;
  right: 23%;
  top: 30%;
}
.min-img3 {
  /* width: 76%; */
  width: 240px;
  z-index: 13;
}
.min-img4 {
  /* width: 65%; */
  width: 200px;
  z-index: 14;
}
.min-img5 {
  width: 140px;
  z-index: 15;
}
.min-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 16;
}
.min-money {
  text-align: center;
  color: #00fff7;
  font-size: 24px;
}
.min-img6 {
  width: 100px;
  margin-top: -5px;
}
.min-text {
  color: #fff;
  font-size: 14px;
}
.min-img1,
.min-img2,
.min-img3,
.min-img4,
.min-img5,
.min-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* 旋转 */
@-webkit-keyframes rotation-min {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
/* 旋转 */
.rotation-min {
  width: 170px;
  -webkit-transform: rotate(360deg);
  animation: rotation 30s linear infinite;
  -moz-animation: rotation 30s linear infinite;
  -webkit-animation: rotation 30s linear infinite;
  -o-animation: rotation 30s linear infinite;
}
.left {
  width: 20%;
  height: 100%;
  padding: 50px 10px 20px 10px;
  box-sizing: border-box;
  background-image: url("../assets/left.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 10;

  display: flex;
  flex-direction: column;
}
.left::-webkit-scrollbar {
  display: none;
}
.left-topimg {
  width: 100px;
  margin: 30px auto;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* box-shadow: 0 0 15px rgba(0,0,0, 0.2); */
  padding: 10px;
}
.left-img {
  width: 100px;
}
.project_main{
  width: 100%;
  overflow-y: scroll;
}
.project_main::-webkit-scrollbar {
  display: none;
}
.center {
  width: 40%;
  z-index: 10;
  margin: 0 10%;
  margin-top: -150px;
  position: relative;
  /* background-image: url("../assets/yibiao@3x.png");
  background-size: contain;
  background-repeat: no-repeat; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-img1 {
  /* width: 100%; */
  width: 728px;
  z-index: 11;
}
.c-img2 {
  /* width: 88%; */
  width: 634px;
  z-index: 12;
}
.c-img3 {
  /* width: 76%; */
  width: 551px;
  z-index: 13;
}
.c-img4 {
  /* width: 65%; */
  width: 452px;
  z-index: 14;
}
.c-img5 {
  width: 250px;
  z-index: 15;
}
.c-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 16;
}
.c-money {
  text-align: center;
  color: #00fff7;
  font-size: 45px;
}
.c-img6 {
  width: 205px;
  margin-top: -10px;
}
.c-text {
  color: #fff;
  font-size: 25px;
}

.c-img1,
.c-img2,
.c-img3,
.c-img4,
.c-img5,
.c-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.show {
  opacity: 1;
  /* visibility: visible; */
  transition: all 0.7s;
}
.hidden {
  opacity: 0;
  /* visibility: hidden; */
  transition: all 0.7s;
}
.center-C {
  width: 520px;
  height: 520px;
  z-index: 16;
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.center-min {
  width: 190px;
  height: 190px;
  z-index: 16;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 旋转 */
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
/* 旋转 */
.rotation {
  width: 398px;
  -webkit-transform: rotate(360deg);
  animation: rotation 30s linear infinite;
  -moz-animation: rotation 30s linear infinite;
  -webkit-animation: rotation 30s linear infinite;
  -o-animation: rotation 30s linear infinite;
}
.right {
  width: 20%;
  height: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  background-image: url("../assets/right.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 10;
  overflow-y: scroll;
  position: relative;
}
.right::-webkit-scrollbar {
  display: none;
}
.project {
  width: 100%;
  margin-bottom: 50px;
  box-sizing: border-box;
}
.project-top {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}
.project-title {
  width: 100%;
  display: flex;
  align-items: center;
}
/* .type {
  position: absolute;
  top: 15%;
  left: 0;
  color: #fff;
  font-size: 16px;
} */
.project-name {
  color: #00fff7;
  font-size: 24px;
  cursor: pointer;
}
.project-img {
  width: 13%;
  margin-right: 10px;
}
.project-msg {
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.msg {
  width: 47%;
  min-height: 88px;
  background-image: url("../assets/bg1@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.msg-title {
  color: #eee;
  font-size: 16px;
  margin-bottom: 10px;
}
.msg-num {
  color: #fafa5b;
  font-size: 24px;
  font-weight: bold;
}
.total {
  width: 100%;
  height: 30%;
  margin-top: 40px;
  box-sizing: border-box;
}
.total-title {
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}
.total-title-l{
  display: flex;
  align-items: center;
}
.total-title-r .el-dropdown{
  color: #FFFFFF;
  font-size: 16px;
}
.ranking .total-title{
  justify-content: space-between;

}
.iconbox {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50px;
  border: 1px solid #ffcb00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
}
.vip {
  width: 100%;
  margin-top: 20px;
}

.vip-count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vip-l {
  color: #fff;
  font-size: 16px;
}
.vip-r {
  color: rgb(0, 255, 247);
  font-size: 32px;
  font-weight: bold;
}
.vip-line {
  width: 100%;
  margin-top: -10px;
}
.chartsbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.charts {
  width: 26%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.chart-l {
  width: 100%;
  min-height: 100px;
}
.card {
  width: 100%;
  min-height: 47px;
  background-image: url("../assets/zu@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
}
.details {
  width: 100%;
  margin-top: 20px;
}
.bean {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.bean-l,
.bean-r {
  display: flex;
  align-items: center;
  align-items: center;
}
.bean-img {
  width: 30%;
  min-width: 48px;
  margin-right: 8px;
}
.bean-msg {
  text-align: center;
}
.bean-count {
  font-size: 24px;
  color: #00fff7;
}
.bean-text {
  color: #eee;
  font-size: 14px;
}
.ranking {
  width: 90%;
  height: 32%;
  position: relative;
  top: 6%;
}
.ranks {
  width: 100%;
  height: 100%;
  margin-left: -10px;
  margin-top: -10px;
}

.center_b{
  width: 58%;
  height: 35%;
  position: absolute;
  left: 20%;
  bottom: 0;
  padding: 0 1%;
  font-size: 25px;
  font-weight: 500;
  color: white;
  z-index: 11;
}
.center_b_l{
  width: 49.1%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  background: url("../assets/Group 6092.png") top left/100% 100% no-repeat ;
}
.center_b_l_t{
  margin-top: 20%;
  height: 46%;
  display: flex;
  justify-content: space-around;
}
.center_b_l_t_l{
  width: 27%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}
.center_b_l_t_l div:nth-of-type(1){
  background: url("../assets/Group 6061.png") center center/100% 100% no-repeat;
  height: 73%;
}
.center_b_l_t_l div:nth-of-type(1) span{
  font-size: 28px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #04FFFF;
}
.center_b_l_t_l div:nth-of-type(2){
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.center_b_l_t_r{
  width: 27%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}
.center_b_l_t_r div:nth-of-type(1){
  background: url("../assets/Group 6061.png") center center/100% 100% no-repeat;
  height: 73%;
}
.center_b_l_t_r div:nth-of-type(1) span{
  font-size: 28px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #04FFFF;
}
.center_b_l_t_r div:nth-of-type(2){
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

.center_b_l_b{
  width: 96%;
  margin-left: 3%;
  margin-top: 16px;
  height: 17%;
  background: #fafa5b;
  background: url("../assets/Rectangle 3625.png") center center/100% 100% no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.center_b_l_b span:nth-of-type(1){
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-left: 13px;
}
.center_b_l_b span:nth-of-type(2){
  font-size: 26px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #04FFFF;
  margin-right: 14px;
}

.center_b_r{
  width: 49.1%;
  height: calc(100% - 40px);
  background: url("../assets/Group 6094.png") top left/100% 100% no-repeat ;
  float: right;
  padding-top: 40px;

  /*方案一*/
  display: flex;
  justify-content: space-around;
  align-items: center;

  /*方案二*/
/*  display: flex;
  flex-wrap: wrap;
  align-items: center;*/
}
/*方案一*/
.center_b_r_item{
  height: 41%;
  width: 17%;
  text-align: center;
}
.center_b_r_item div:nth-of-type(1){
  height: 71%;
  width: 100%;
  background: url("../assets/Group 6067.png") center center/100% 100% no-repeat;
  font-size: 26px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FAFA5B;
}
.center_b_r_item div:nth-of-type(2){
  margin-top: 5px;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}

/*方案二*/
/*.center_b_r_item{
  width: 80%;
  height: 25%;
  margin: 2% auto;
  display: flex;
  align-items: center;
  justify-content: right;
}
.center_b_r_item:nth-of-type(1){
  background: url("../assets/Group 6095.png") top left/100% 100% no-repeat;
}
.center_b_r_item:nth-of-type(2){
  background: url("../assets/Group 6086.png") top left/100% 100% no-repeat;
}
.center_b_r_item:nth-of-type(3){
  background: url("../assets/Group 6088.png") top left/100% 100% no-repeat;
}
.center_b_r_item span:nth-of-type(1){
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.center_b_r_item span:nth-of-type(2){
  font-size: 26px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FAFA5B;
  margin-right: 5%;
  width: 25%;
  text-align: right;
}*/

.vip_main{
  width: 100%;
  height: 65%;
  padding-top: 5%;
  padding-bottom: 10px;
}
.vip_main div{
  width: 43%;
  height: 50%;
  background: url("../assets/Rectangle 3618.png") top left/100% 100% no-repeat;
  margin-right: 7%;
  margin-bottom: 5%;
  float: left;
}
.vip_main>div span{
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 6%;
  padding-left: 5%;
  display: block;
}
.vip_main>div span:nth-of-type(2){
  font-size: 26px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #01F7F1;
  padding-top: 3%;
}


.ranking_main{
  width: 100%;
  height: 80%;
  margin-left: -10px;
  margin-top: -10px;
  font-size: 14px;
  padding-left: 20px;
  padding-top: 10px;
  color: #f3f3f3;
  font-weight: 100;
}
.ranking_main div{
  width: 100%;
  height: 20%;
  margin-top: 5%;
  background: url("../assets/Line 6 (Stroke).png") left bottom/100%  no-repeat;
}
.ranking_main>div span{
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  float: left;
}
.ranking_main>div span:nth-of-type(2){
  font-size: 26px;
  font-family: Inter-Regular, Inter;
  line-height: 30px;
  float: right;
}
.merchant_number{
  width: 8%;
  padding: 20px 0 9px;
  position: absolute;
  top: 3%;
  left: 23%;
  background: url("../assets/Rectangle 3622.png") 0 0/100% 100% no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
}
.merchant_number img{
  width: 23.75%;
  height: 23.61%;
}
.merchant_number .name{
  margin-top: 5%;
  font-size: 19px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #FFFFFF;
}
.merchant_number .number{
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #F9F95B;
  line-height: 45px;
}
</style>
<style>
.index_dropdown_menu{
  background-color: rgba(26, 53, 91, 0.65);
  border-radius: 4px;
  border-image: linear-gradient(rgba(63, 192, 226, 0.33), rgba(63, 192, 226, 0.92),#3FC0E2, rgba(63, 192, 226, 0.35));
  backdrop-filter: blur(15px);
  border: none;
}
.index_dropdown_menu .el-dropdown-menu__item{
  color: #FFFFFF;
  position: relative;
}
.index_dropdown_menu .el-dropdown-menu__item:focus,.index_dropdown_menu .el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: transparent;
  color: #FFFFFF;
}
.index_dropdown_menu .popper__arrow{
  border-bottom-color:rgba(26, 53, 91)!important;
}
.index_dropdown_menu .popper__arrow::after{
  border-bottom-color:rgba(26, 53, 91, 0.65) !important;
}
.index_dropdown_menu .el-dropdown-menu__item::before{
  width: 29px;
  height: 2px;
  background-color: #44D1F3;
  position: absolute;
  top: 0;
  left: 0;
}
.cursor_href{
  cursor: pointer;
}
</style>
